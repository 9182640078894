import React from 'react';
import Header from './Header';
import Form from './Form';
import Footer from './Footer';

function App() {
  return (
    <React.Fragment>
      <Header />
      <Form />
      <Footer />
    </React.Fragment>
  );
}

export default App;
