import React from 'react';

function Header() {
    return (
        <section className='header'>
            <h1 className='title'>WAsub</h1>
            <p className='subtitle'>
                {/* Penghantaran mesej ke aplikasi WhatsApp kini lebih mudah tanpa menyimpan nombor telefon penerima.
                <br /> */}
                Isikan maklumat di ruangan berwarna putih.
            </p>
        </section>
    );
    }
 
export default Header;

