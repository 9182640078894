import React from 'react';

function Footer() {
    return (
        <section className='footer'>
            <p className='copyright'>
                Penafian: Laman ini adalah bukan hak milik syarikat yang mengendalikan aplikasi WhatsApp.
                <br/><br/>
                Made with {'\u2665'} by sub.my {'\u00A9'} 2022
            </p>
        </section>
    );
    }
 
export default Footer;

