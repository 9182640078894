import React, { useState } from 'react';

function Form() {
    const [nombor, setNombor] = useState('');
    const [mesej, setMesej] = useState('');
    const waAPI = 'https://wa.me';

    let url = `${waAPI}/${nombor}`;
    
    if (mesej) {
        url += `?text=${encodeURIComponent(mesej)}`;
    }

    return (
        <section className='content'>
            <label>Nombor Telefon:</label>
            <p className='description'>Mulakan dengan kod negara dan tanpa simbol '+'</p>
            <input
                id='nomborTelefon'
                type='text'
                placeholder='Contoh: 60198764321'
                value={nombor}
                onChange={ (e) => setNombor(e.target.value)}
            />
            <label>Mesej:</label>
            <textarea 
                id='teksMesej'
                type='text'
                placeholder='Mesej di sini...'
                value={mesej}
                onChange={ (e) => setMesej(e.target.value) }
            />
            <button
                id='hantar'
                type="submit"
                onClick={ (e) => window.open(url) }
            >Hantar WhatsApp</button>
        </section>
    );
    }
 
export default Form;

